<template>
	<div :ref="`deliverItem${dataSource.id}`" class="deliverItem" @click="$emit('goDetail')">
		<div class="info">
			<div class="orderTitle-id mb-8">
				<span class="blod">{{dataSource.orderType === 3? dataSource.appOrderId: dataSource.dcosOrderId}}</span>
				<!-- 审核状态 -->
                <span v-if="queryType === 1 && dataSource.deliveryOffsiteStatus" :class="['status',`status_${dataSource.deliveryOffsiteStatus}`]">
					{{ dataSource.deliveryOffsiteStatus | codeFormat }}
				</span>
                <span v-if="queryType === 2 && dataSource.deliveryCostStatus" :class="['status',`status_${dataSource.deliveryCostStatus}`]">
					{{ dataSource.deliveryCostStatus | codeFormat }}
				</span>
			</div>
			<div v-if="dataSource.customerName" class="name mb-8">
				{{ dataSource.customerName }}
			</div>
			<div class="tag mb-8">
				<div>
					{{ dataSource.customerType == 1 ? $t('个人') : $t('企业') }}
				</div>
				<template v-if="[1, 0].includes(dataSource.installmentsFlag)">
					<div class="gap"></div>
					<div>
						{{ dataSource.installmentsFlag == 1 ? $t('分期') : $t('全款') }}
					</div>
				</template>
			</div>
			<div v-if="carInfo" class="car-info mb-8" v-html="carInfo"></div>
			<div class="mb-8" style="color: rgba(13,23,26,0.45)">VIN： {{ dataSource.vin || '--' }}</div>
			<div style="color: rgba(13,23,26,0.45)">{{ dataSource.deliveryMethod.toString() | codeFormat  }}</div>
		</div>
		<div class="bottom">
			<div class="follow-user">
                <span class="followUp">申请人：{{ dataSource.deliveryUserName }}</span>
                <span class="followUp">{{ dataSource.offsiteApplyTime }}</span>
			</div>
		</div>
	</div>
	</template>
<script>
import { mapGetters } from 'vuex'
import { ROLES } from '@/constants/constants'
import store from '@/store'
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({})
    },
    queryType: {
      type: Number,
      default: () => (1)
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'userInfo']),
    orderLabels() {
      return this.dictHash[4009].filter(({ code }) => { return this.dataSource.orderLabel.includes(code) })
    },
    carInfo() {
      const {
        seriesName,
        modelName,
        colourName,
        interiorName,
      } = this.dataSource
      const arr = []
      if (seriesName) {
        arr.push(`<span>${seriesName}</span>`)
      }
      if (modelName) {
        arr.push(`<span>${modelName}</span>`)
      }
      if (colourName) {
        arr.push(`<span>${colourName}</span>`)
      }
      if (interiorName) {
        arr.push(`<span>${interiorName}</span>`)
      }
      return arr.join(
        '<span class="gap" style="width: 1px;margin: 0 8px;height: 12px;background-color: #d9d9d9;"></span>'
      )
    },
    // 是否交付店长
    isDeliverManage() {
      const { roleLogos=[] } = this.userInfo
      return roleLogos.includes(ROLES.DELIVERMANAGE)
    },
    // // 是否是自己的数据
    // isDeliverSalesOwner() {
    //   const { roleLogos=[], id } = this.userInfo
    //   return roleLogos.includes('1014006') && (this.dataSource.deliveryUserId == id || this.dataSource.authedDeliveryUserId == id)
    // },
  },
  methods: {
  }
}
</script>
	<style lang="less" scoped>
	.deliverItem{
		background: #fff;
		box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
		border-radius: 8px;
		padding: 16px 12px 0px 12px;
		font-size: 13px;
		.van-ellipsis{
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.info{
			position: relative;
			padding-bottom: 12px;
			/deep/ .label-component{
				margin: 0 0 8px 0;
			}
			.mb-8 {
				margin-bottom: 8px;
			}
			.car-info {
				display: flex;
				align-items: center;
				color: rgba(13, 23, 26, 0.45);
			}
			.orderTitle-id {
				display: flex;
				align-items: center;
				padding-bottom: 12px;
				border-bottom: 1px solid rgba(13, 23, 26, 0.05);
				line-height: 16px;
				.blod {
					flex: 1;
				}
				.status {
					font-size: 14px;
				}
				// 待审核
				.status_4014001{
					color: @black;
				}
				.status_4014015{
					color: #029640;
				}
				.status_4014020{
					color: red;
				}
			}
			.tag {
				display: flex;
				align-items: center;
				color: #b9921a;
			}

			.gap {
				width: 1px;
				margin: 0 8px;
				height: 12px;
				background-color: #d9d9d9;
			}
			.options {
				display: flex;
				flex-wrap: wrap;
				margin-left: -4px;
				margin-right: -4px;
				.li {
					padding: 2px 8px;
					border-radius: 4px;
					border-width: 1px;
					border-style: solid;
					margin-top: 8px;
					margin-left: 4px;
					line-height: 16px;
					font-size: 12px;
					background: rgba(102,102,102,0.1);
					border: 1px solid rgba(102,102,102,0.1);
					&-4011003 {
						border-color: rgba(255, 125, 0, 0.1);
						color: #ff7d00;
						background: rgba(255, 125, 0, 0.1);
					}

					&-202402 {
						color: #029640;
						background: rgba(2, 150, 64, 0.1);
						border-color: rgba(2, 150, 64, 0.1);
					}
					&-40050012 {
						color: #b9921a;
						background: rgba(185, 146, 26, 0.1);
						border-color: rgba(185, 146, 26, 0.1);
					}
					&:last-child {
						margin-right: 4px;
					}
				}
			}
		}
		.bottom{
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: 1px solid rgba(13,23,26,0.05);
			height: 53px;
			.follow-user{
				font-size: 13px;
				color: #0D171A;
				line-height: 20px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				.followUp{
					font-size: 14px;
					color: @yellow-text;
					margin-right: 10px;
					// padding-left: 16px;
				}
			}
			.icons{
				display:flex;
				padding: 8px 0 12px 0;
				justify-content: space-between;
				.icon{
					margin-left: 16px;
					&:first-of-type {
						margin-left: 0;
					}
				}
			}
		}
		.check-item{
			position: absolute;
			right: 0px;
			top: calc(50% - 8px);
			z-index: 1;
			/deep/.van-checkbox__icon{
				font-size: 16px;
				.van-icon{
						border: 2px solid rgba(13,23,26,0.45);
				}
			}
			/deep/.van-checkbox__icon--checked .van-icon{
				background-color: #0D171A;
				border-color: #0D171A;
				line-height: 1;
			}
		}
	}
	</style>
	