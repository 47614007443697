<template>
	<div class="remote-deliver-approve">
    <div class="content-l">
      <div v-for="(item,index) in tabs" :key="index" :class="[type === item.value?'current':'','type-item']" @click="changeTab(item)">{{ item.name }}({{ item.num }})</div>
    </div>
    <div class="content-r">
      <div class="header">
        <div>
            <van-dropdown-menu active-color="#B9921A">
                <van-dropdown-item
                    v-model="taskCode"
                    :options="options"
                    @change="onRefresh"
                />
            </van-dropdown-menu>
        </div>
        <p class="total">{{ $t('共') }} {{ totalCount }} {{ $t('条数据') }}</p>
      </div>
      <List v-if="$store.state.user.userInfo.id" ref="list" :fetchApi="fetchApi">
        <template v-slot:default="{ item }">
                  <ApproveItem :dataSource="item" class="order" :queryType="type" @click.native="toDetail(item)"></ApproveItem>
        </template>
      </List>
    </div>
	</div>
</template>
<script>
import List from '@/components/baseList/list.vue'
import ApproveItem from '@/modules/delivery-assistant/components/approveItem.vue'
import loading from '@/utils/loading'
import todoServices from '@/services/todoServices.js'
import { dateFormat } from '@/utils'
// import { remoteDeliverRequest } from '@/services/deliveryAssistant.js'
import { mapGetters } from 'vuex'
import deliveryServices from '@/services/deliveryServices.js'
let vm = this
export default {
  name: 'RemoteDeliverApprove',
  filters: {
    sourceText(data) {
      let arr = []
      const { channelOne, channelTwo } = data
      channelOne && arr.push(channelOne)
      channelTwo && arr.push(channelTwo)
      return arr.length > 0 ? arr.join('/') : vm.$t('暂无')
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'RemoteDeliverApprove')
    })
  },
  components: {
    List,
    ApproveItem,
  },
  data() {
    const that = this
    return {
      totalCount: 0,
      taskCode: '',
      type: 1,
      tabs:[
        {
          name: this.$t('交付申请'),
          value: 1,
          get num() {
            return that.countInfo[1] || 0
          }
        },
        {
          name: this.$t('费用申请'),
          value: 2,
          get num() {
            return that.countInfo[2] || 0
          }
        },
      ],
      countInfo:{}
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    options() {
      const arr = this.dictHash && this.dictHash[4014] || []
      const arrList = arr.map((item) => ({ text: item.name, value: item.code }))
      arrList.unshift({ text: '全部', value: '' })
      return arrList
    }
  },
  mounted(){
    this.getCount()
  },
  activated() {
    this.$refs.list.onRefresh()
  },
  methods: {
    changeTab(typeItem){
      this.type = typeItem.value
      this.$refs.list.onRefresh()
    },
    onRefresh() {
      this.$refs.list.onRefresh()
    },
    // 获取列表数据
    fetchApi(obj = {}) {
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        queryType: this.type === 1 ? 40 : 45,
        searchStr: '',
      }
      if (this.type === 1) {
        params.deliveryOffsiteStatus = this.taskCode ? [this.taskCode] : []
      } else if (this.type === 2) {
        params.deliveryCostStatus = this.taskCode ? [this.taskCode] : []
      }
      loading.showLoading()
      return deliveryServices.getDeliverList(params).then((res) => {
        console.log(res,'page request')
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        this.countInfo[this.type] = res.page.totalCount
        return res
      })
    },
    getCount() {
      const params = {
        queryType: 45
      }
      deliveryServices.getDeliverList(params).then((res) => {
        this.$set(this.tabs, 1, {
          name: this.$t('费用申请'),
          value: 2,
          get num() {
            return res.page.totalCount
          }
        })
      })
    },
    toDetail({ id }){
      this.$router.push({
        path: this.type === 1 ? '/remoteDelivery' : '/remoteDeliveryFee',
        query: {
          id
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.remote-deliver-approve {
  display: flex;
  .content-l{
      font-size: 14px;
      width: 100px;
      border-right: 2px solid #fff;
      
      .type-item{
        cursor: pointer;
        padding: 12px;
      }
      .current{
        color: #B9921A;
        background: #fff;
      }
  }
  .content-r{
    position: relative;
    width: calc(100% - 100px);
    // width: 100%;
    .header{
        display: flex;
        justify-content: space-between;
        background: #fff;
        /deep/ .van-dropdown-menu__bar {
            padding-left: 8px;
            padding-right: 16px;
            justify-content: space-between;
            box-shadow: none;
        }
        /deep/ .van-dropdown-menu .van-dropdown-item {
          position: absolute;
        }
    }
    .total {
      height: 22px;
      font-size: 14px;
      font-family: HYQiHei, HYQiHei;
      font-weight: normal;
      color: #262626;
      line-height: 22px;
      margin: 9px 16px 0;
    }
  }
}
</style>
